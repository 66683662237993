import 'firebase/compat/database';
import { typeMap, newObjectMap } from '@/json/listBuilderData.json';

export default {
  data() {
    const initialChunkSize = 50;
    const subsequentChunkSize = 5;
    return {
      //Chunking variables
      type: null,
      databasePath: null,
      campgroundKey: null,
      locationDataRef: null,
      initialChunkSize,
      subsequentChunkSize,
      chunkSize: initialChunkSize,
      lastLoadedKey: null,
      //Chunking arrays
      chunkKeys: [],
      chunkList: [],
      prefetchKeys: [],
      prefetchList: [],
      cachedKeys: [],
      cachedList: [],
      //Loading states
      isLoadingInitialChunks: true,
      isLoadingChunk: false,
      isClearingChunks: false,
      isScrollDisabled: false,
      chunksLoaded: false,
      view: 'view-active',
      //Actions / context variables
      actions: {
        default: () => {
          this.rightColumnProps = {
            buttonSection: 'actions',
            context: this.context,
            dbContext: this.dbContext,
            displayType: this.displayType,
          };
          if (this.displayType) {
            this.rightColumnComponent = 'ButtonNavigation';
          }
        },
      },
      action: null,
      params: null,
      dbContext: null,
      context: 'events',
      displayType: 'events',
      tabs: null,
      viewArchived: false,
      isSystemwide: false,
      //Right column variables
      rightColumnKey: null,
      rightColumnProps: {},
      rightColumnComponent: 'ButtonNavigation',
      rightColumnItem: {},
      //Header image mapping
      headerContextMap: {
        'events-resort': 'events',
        'events-local': 'events',
        'services-resort': 'services',
        'services-local': 'services',
        'recreation-resort': 'recreation',
        'recreation-local': 'recreation',
        'reservations-lodging': 'reservations',
        'reservations-misc': 'reservations',
        'maps-resort': 'maps',
        'maps-local': 'maps',
        'dining-resort': 'dining',
        'dining-local': 'dining',
        'dining-delivery': 'dining',
      },
      _debug: false,
      bulkCheckAll: false,
      bulkSelected: [],
      filterText: '',
      filterStartDate: null,
      filterEndDate: null,
      showOverlay: false,
    };
  },
  watch: {
    bulkCheckAll: async function (val) {
      await this.loadOnSelectAll();
      this.bulkSelected = val ? this.chunkKeys : [];
    },
    filterText: async function (newVal, oldVal) {
      if (newVal == null || newVal == '') {
        this.resetToCached();
      }

      await this.loadOnSelectAll();
      this.filterData();
    },
    filterStartDate: async function (newVal, oldVal) {
      await this.loadOnSelectAll();
      this.filterData();
    },
    filterEndDate: async function (newVal, oldVal) {
      await this.loadOnSelectAll();
      this.filterData();
    },
    chunkKeys: function (newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const diff = newVal.filter((item) => !oldVal.includes(item));
        diff.forEach((key) => {
          if (!this.cachedKeys.includes(key)) {
            this.cachedKeys.push(key);
          }
        });
        diff.forEach((key) => {
          let item = this.getItemByKey(key);
          if (!this.cachedList.includes(item)) {
            this.cachedList.push(item);
          }
        });
      }
    },
  },
  computed: {
    centerComponent() {
      return typeMap[this.type][this.tabIndex].listComponent;
    },
  },
  created() {
    this.campgroundKey = this.$route.params.context !== 'systemwide-notification' ? this.getCampgroundKey : 'system-data';
    this.locationDataRef = this.getLocationRef(this.campgroundKey);
  },
  methods: {
    //Infinite scrolling setup
    setRouteProperties() {
      const params = this.$route.params;
      if (params) {
        this.context = params.context;
        this.tabIndex = params.tab || 0;
        this.isSystemwide = this.context == 'systemwide-notification';
      }
    },
    setDatabasePath(path, context) {
      if (this.databasePath) this.databasePath.off();

      this.dbContext = context;
      this.databasePath = path.child(context);
      if (this._debug) {
        console.log('Set path to:', this.databasePath.toString());
        console.log('Set dbContext to:', this.dbContext);
      }
    },

    //Infinite scrolling data management functions
    async nextChunk() {
      if (this.isLoadingChunk || this.chunksLoaded) return;
      this.isLoadingChunk = true;
      this.$emit('chunks-loading');

      if (this.prefetchList && this.prefetchList.length > 0) {
        this.chunkList.push(...this.prefetchList);
        this.chunkKeys.push(...this.prefetchKeys);
        if (this._debug) console.log(`Used ${this.prefetchList.length} prefetched data for next chunk.`);
        this.onDataPush();
        this.prefetchList = null;
        this.prefetchKeys = null;
      } else {
        const { list, keys } = await this._getChunk('main chunk');
        this.chunkList.push(...list);
        this.chunkKeys.push(...keys);
        this.onDataPush();
      }
      this.isLoadingChunk = false;

      const { list, keys } = await this._getChunk('prefetch chunk');
      this.prefetchList = list;
      this.prefetchKeys = keys;
      if (this._debug) console.log(`Prefetched ${list.length} items for next chunk.`);

      //this.verifyChunkSize();
    },
    async _getChunk(target) {
      const result = { keys: [], list: [] };
      let query = this.chunkQuery(this.databasePath);

      try {
        const data = (await query.once('value')).val() || null;
        if (data) {
          const dataArray = this.getDataArray(data);
          const keyArray = this.getKeyArray(data);

          // If lastLoadedKey is set, skip the first record
          if (this.lastLoadedKey) {
            dataArray.shift();
            keyArray.shift();
            if (dataArray.length == 0) {
              this.chunksLoaded = true;
              this.$emit('chunks-loaded');
              console.log('All chunks loaded!');
            }
          }

          //this.clearKeyConflicts();
          this.logKeyConflicts();

          result.list.push(...dataArray);
          result.keys.push(...keyArray);
          this.lastLoadedKey = result.keys[result.keys.length - 1];
          if (this._debug) {
            console.log(`Assigned lastLoadedKey to ${this.lastLoadedKey} for ${target}.`);
            console.log(`Loaded ${result.keys.length} items for ${target}.`);
          }
        } else {
          this.chunksLoaded = true;
          console.log('Database node is empty.', this.databasePath.toString());
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }

      if (this.isLoadingInitialChunks == true) {
        console.log('Initial chunks loaded.');
        this.isLoadingInitialChunks = false;
        this.initialLoad();
        this.chunkSize = this.subsequentChunkSize;

        this.cachedKeys = [...result.keys];
        this.cachedList = [...result.list];
      }

      return result;
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.isScrollDisabled == false && this.chunksLoaded == false && scrollTop + clientHeight >= scrollHeight - scrollHeight * 0.2) {
        await this.nextChunk();
      }
    },
    // filterSetup(newVal, oldVal) {
    //   if (!oldVal) {
    //     this.chunkKeysOrig = [...this.chunkKeys];
    //     this.chunkListOrig = [...this.chunkList];
    //   }
    //   if (!newVal) {
    //     this.chunkKeys = [...this.chunkKeysOrig];
    //     this.chunkList = [...this.chunkListOrig];
    //   }
    //   this.chunkList = [];
    //   this.chunkKeys = [];
    // },

    //Override these functions in the component for custom behavior (e.g, sorting, filtering, etc)
    chunkQuery(query) {
      if (this.lastLoadedKey) query = query.orderByKey().startAt(String(this.lastLoadedKey));
      query = query.limitToFirst(this.chunkSize);
      return query;
    },
    getDataArray(data) {
      return Object.values(data);
    },
    getKeyArray(data) {
      return Object.keys(data);
    },

    //Filtering functions
    filterData() {
      this.chunkKeys = [];
      this.chunkList = [];

      this.chunkKeys = this.cachedKeys.filter((_, index) => {
        const item = this.cachedList[index];
        if (this.checkFilters(item)) {
          this.chunkList.push(item);
          return true;
        }
        return false;
      });

      this.checkScrollDisable();
    },
    //This function should be overridden in the component to filter the data based on the filter text.
    checkFilters(item) {
      const text = this.filterText.toLowerCase();
      if (this.isFilterContains(item.title, text)) return true;
      if (this.isFilterContains(item.description, text)) return true;
    },
    isFilterContains(value, text) {
      return (value || '').toLowerCase().includes(text);
    },
    checkScrollDisable() {
      if (this.filterText || this.filterStartDate || this.filterEndDate) this.isScrollDisabled = true;
      else this.isScrollDisabled = false;
    },
    async loadOnSelectAll() {
      if (this.chunksLoaded == false && this.isClearingChunks == false) {
        this.showOverlay = true;
        await this.loadAll();
        this.showOverlay = false;
      }
    },

    //Called after each chunk is loaded to filter or modify the data.
    onDataPush() {},
    //Called after the initial chunk is loaded (or when chunksLoaded is true for data less than chunk size).
    initialLoad() {},

    //Right column functions
    addAction(name, func) {
      if (!name || name == '') {
        console.error('addAction: name is required');
        return;
      }
      if (!func || typeof func !== 'function') {
        console.error('addAction: func is required and must be a function');
        return;
      }

      this.actions[name] = func;
    },
    async setAction(action) {
      this.action = action;
      this.params = arguments;

      if (this.actions.hasOwnProperty(action)) {
        if (arguments.length == 1) console.log(`Calling ${action} action.`);
        else console.log(`Calling ${action} action with ${arguments.length - 1} arguments.`, arguments);
        await this.actions[action](this.params);
      } else {
        console.log('Calling default action.');
        this.actions.default(this.params);
      }
    },
    editItem(key) {
      this.rightColumnKey = key;
      this.setAction('edit');
    },
    deleteItem(keys) {
      const keyCopy = [...keys];
      if (!Array.isArray(keyCopy)) keyCopy = [keyCopy];

      const removeFrom = (key, keys, list) => {
        const index = keys.indexOf(key);
        if (index > -1) {
          keys.splice(index, 1);
          list.splice(index, 1);
        }
      };

      keyCopy.forEach((key) => {
        removeFrom(key, this.chunkKeys, this.chunkList);
        removeFrom(key, this.cachedKeys, this.cachedList);
      });
    },

    //Chunk management functions
    getItemByKey(key) {
      return this.chunkList[this.chunkKeys.indexOf(key)];
    },
    getKeyByItem(item) {
      return this.chunkKeys[this.chunkList.indexOf(item)];
    },
    changeView(view) {
      this.clearChunks();
      this.action = null;
      this.view = view;
      this.isArchived = view == 'view-archived';
    },
    async loadAll() {
      const data = (await this.databasePath.once('value')).val() || null;
      if (data) {
        this.chunkList = this.getDataArray(data);
        this.chunkKeys = this.getKeyArray(data);
        this.chunksLoaded = true;
        this.$forceUpdate();
      } else {
        console.log('Database node is empty.', this.databasePath.toString());
      }
    },
    resetToCached() {
      this.chunkList = this.cachedList;
      this.chunkKeys = this.cachedKeys;
    },
    bulkCheckAllItems() {
      this.bulkSelected = this.chunkKeys;
    },
    bulkCheckItem(key, checked) {
      if (checked) {
        this.bulkSelected = this.bulkSelected.filter((item) => item !== key);
      } else {
        this.bulkSelected.push(key);
      }
    },

    //This method prevents the component from not being able to trigger more chunks
    //if the initial chunk is less than the chunk size due to filtering.
    async verifyChunkSize() {
      if (this.chunkList.length < this.chunkSize && !this.chunksLoaded) {
        if (this._debug) console.log(`Chunk size under limit and not fully loaded (${this.chunkList.length} < ${this.chunkSize})`);
        await this.nextChunk();
      }
    },
    clearChunks() {
      this.isClearingChunks = true;
      this.chunkSize = this.initialChunkSize;
      this.chunkKeys = [];
      this.chunkList = [];
      this.prefetchKeys = [];
      this.prefetchList = [];
      this.cachedKeys = [];
      this.cachedList = [];
      this.bulkSelected = [];
      this.bulkCheckAll = false;

      this.isLoadingInitialChunks = true;
      this.isLoadingChunk = false;
      this.chunksLoaded = false;
      this.lastLoadedKey = null;
      this.$nextTick(() => (this.isClearingChunks = false));
    },
    clearKeyConflicts() {
      const keySet = new Set(this.chunkKeys);
      if (keySet.size !== this.chunkKeys.length) {
        this.chunkList = this.chunkList.filter((item, index) => this.chunkKeys.indexOf(this.getKeyByItem(item)) === index);
        this.chunkKeys = this.chunkKeys.filter((key, index) => this.chunkKeys.indexOf(key) === index);
      }
    },
    logKeyConflicts() {
      const keySet = new Set(this.chunkKeys);
      if (keySet.size !== this.chunkKeys.length) {
        const conflicts = this.chunkKeys.filter((key, index) => this.chunkKeys.indexOf(key) !== index);
        if (this._debug) console.warn('Key conflicts:', conflicts);
      }
    },
  },
};
